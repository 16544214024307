import React, { useCallback, useRef, useEffect } from "react"
import styled from 'styled-components';
import { gsap } from "gsap";
import { Link, graphql } from 'gatsby';
import { ScrollTrigger } from 'gsap/all';
import { SiteContext } from "context/site-context";
import Section from "components/Section/Section";
import BrandScaling from "components/BrandScaling/BrandScaling";
import Thumbnail from "components/Thumbnail/Thumbnail";
import MobileCopy from "components/MobileCopy/MobileCopy";
import { media } from "utils/mediaQuery";
import { getValuesFromQuery } from "utils/filter";
import useAlbumFilter from "utils/useAlbumFilter";

import Layout from "components/Layout/Layout"
import Seo from "../components/seo"

gsap.registerPlugin(ScrollTrigger);

const HomeLayout = styled(Layout)`
  background: rgb(var(--theme-background));
`

const HomeSection = styled(Section)`
  
`

const HomeGrid = styled.div` 
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 16px;
  row-gap: 40px;
  padding: 40px 0 88px;

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.large`
    grid-template-columns: repeat(3, 1fr);
    padding: 40px 0 0;
  `}

  ${media.xxlarge`
    grid-template-columns: repeat(4, 1fr);
  `}
`

const FirstGrid = styled(HomeGrid)`
  padding: 96px 0 0;
  visibility: hidden;
  //transform: perspective(1000px) rotateY(305deg) rotateX(18deg) translateX(-400px) translateY(-1000px);
  ${media.large`
    padding: 201px 0 0;
    min-height: 100vh;
  `}
`

const HomeSectionInner = styled.div` 

`

export async function getServerData({ query, ...rest }) {
  const { getFilterResults } = require("../utils/filter")
  const { albums, tags } = await getFilterResults({
    query
  })

  return {
    props: {
      query,
      albums,
      tags
    },
  }
}

const IndexPage = ({ serverData }) => {
  const { jelliesRef, brandRef, navRef, footerRef, footerAttrRef, mobileCopyRef, mobileCopyReference, setHomeIntroComplete, width, cachedRoute } = React.useContext(SiteContext);
  const [ ContainerRef, setContainerRef ] = React.useState();
  const [ LogoRef, setLogoRef ] = React.useState();
  
  const [ isPageLoading, setIsPageLoading ] = React.useState(true);
  const ScrollTriggerRef = useRef();
  const tl = useRef();
  const firstSetEl = useRef();
  const CopyOffset = useRef();
  const Counter = useRef(0);
  // These default values come from the page query string
  const queryParams = getValuesFromQuery(serverData.query)
  const [filters, setFilters] = React.useState(queryParams)
  const initialFilters = React.useMemo(() => queryParams, [])

  const {
    albums
  } = useAlbumFilter(
    filters,
    serverData.albums,
    false,
    serverData.tags,
    initialFilters
    );
  const [ firstSet, setFirstSet] = React.useState(albums ? albums.filter((object,index)=> index < 9) : null)
  const [ secondSet, setSecondSet] = React.useState(albums ? albums.filter((object,index)=> index >= 9) : null)
  
  useEffect(() => {
    if(ContainerRef 
      && jelliesRef
      && brandRef
      && footerRef
      && footerAttrRef
      && navRef
      && LogoRef
      && mobileCopyRef
      && albums
      && Counter.current === 0) {
      Counter.current = 1;
      let mm = gsap.matchMedia();  
        
      const containerWidth = firstSetEl.current.offsetWidth;
      const jellyscale = containerWidth / 336.9;
      const navScale = containerWidth / brandRef.offsetWidth;
      const initRotateY = 300;
      const initRotateX = 18;
      const initTranslateX = -200;
      const initTranslateY = -200;

      gsap.set(navRef, { scale: navScale, autoAlpha: 1});
      gsap.set(firstSetEl.current, { transform: 'perspective(2000px)', autoAlpha: 1});

      

      mm.add("(max-width: 1919px)", () => {
        setFirstSet(albums.filter((object,index)=> index < 9))
        setSecondSet(albums.filter((object,index)=> index >= 9))
      })
      
      mm.add("(min-width: 1920px)", () => {
        setFirstSet(albums.filter((object,index)=> index < 12))
        setSecondSet(albums.filter((object,index)=> index >= 12))
      })
      

      mm.add("(min-width: 900px)", () => {
        gsap.set(jelliesRef, { bottom: 16, right: 16, position: 'fixed', scale: 1, autoAlpha: 1 });
        jelliesRef.style.fontSize = `${jellyscale * 14}px`;
        gsap.set(jelliesRef,{
          position: "fixed",
          bottom: 16,
          right: 16,
          transformOrigin: "right bottom"
        })

        

        tl.current = gsap.timeline({
          scrollTrigger: {
              trigger: ContainerRef,
              id: "hello",
              start: "top top",
              end: "+=2000",
              fastScrollEnd: 1500,
              pin: true,
              scrub: 0.9,
              markers: false,
              onEnter: () => {
                setHomeIntroComplete(false);
                gsap.set(LogoRef,{
                  opacity: 1,
                  display: "block"
                })

                gsap.set(brandRef,{
                  opacity: 0
                })

              },
              onEnterBack: () => {
                gsap.set(jelliesRef,{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  zIndex: 0,
                  transformOrigin: "right bottom"
                })

                gsap.set(LogoRef,{
                  opacity: 1,
                  display: "block"
                })
                

                gsap.set(brandRef,{
                  opacity: 0
                })
              },
              onLeave: self => {
                setHomeIntroComplete(true);

                gsap.set(jelliesRef,{
                  zIndex: 1
                })

                gsap.set(LogoRef,{
                  display: "none"
                })

                gsap.set(brandRef,{
                  opacity: 1
                })
              },
          }
        })

        tl.current.add("intro")
        .to(LogoRef, {
          duration: 1,
          scale: 1 / navScale
        }, "intro")
        .fromTo(jelliesRef, {
          scale: 1,
        }, {
          duration:1,
          scale: 1 / jellyscale
        }, "intro")
        .fromTo(navRef, {
          scale: navScale
        }, {
          duration:1,
          scale: 1
        }, "intro")
        .fromTo(firstSetEl.current, {
          rotateY: initRotateY,
          rotateX: initRotateX,
          x: initTranslateX,
          y: initTranslateY,
        }, {
          duration:1,
          rotateY: initRotateY + 60,
          rotateX: initRotateX - 18,
          x: initTranslateX + 200,
          y: initTranslateY + 200,
        }, "intro")

        setIsPageLoading(false);
        return () => { // optional

        };

      });

      mm.add("(max-width: 899px)", () => {
        gsap.set(jelliesRef, { bottom: 0, right: 0, position: 'relative', autoAlpha: 1 });
        gsap.set(mobileCopyRef, { opacity: 0, autoAlpha: 1 });
        jelliesRef.style.fontSize = '14px';
        gsap.set(LogoRef,{
          display: "none"
        })

        tl.current = gsap.timeline({
          scrollTrigger: {
              trigger: ContainerRef,
              start: "top top",
              end: "+=2000",
              pin: true,
              scrub: true,
              onEnter: () => {
                setHomeIntroComplete(false);

                gsap.set(LogoRef,{
                  opacity: 1,
                  display: "block"
                })

                gsap.set(brandRef,{
                  opacity: 0
                })
                gsap.set(footerRef,{
                  position: "sticky",
                  bottom: 0,
                })

                gsap.set(footerAttrRef,{
                  display: "none",
                })
               
              },
              onEnterBack: () => {
                gsap.set(footerRef,{
                  position: "sticky",
                  bottom: 0,
                })

                gsap.set(LogoRef,{
                  opacity: 1,
                  display: "block"
                })

                gsap.set(brandRef,{
                  opacity: 0
                })

                gsap.set(jelliesRef,{
                  zIndex: 0
                })

                gsap.set(footerAttrRef,{
                  display: "none",
                })
              },
              onLeave: self => {
                const jellies = jelliesRef;
                gsap.set(footerRef,{
                  position: "relative",
                  bottom: 0,
                })

                gsap.set(jelliesRef,{
                  zIndex: 1
                })

                gsap.set(LogoRef,{
                  display: "none"
                })

                gsap.set(brandRef,{
                  opacity: 1
                })

                gsap.set(footerAttrRef,{
                  display: "block",
                })
                
      
                setHomeIntroComplete(true);
              },
          }
        })


        tl.current.add("intro")
        .to(LogoRef, {
          duration: 1,
          scale: 1 / navScale
        }, "intro")
        .fromTo(navRef, {
          scale: navScale
        }, {
          duration:1,
          scale: 1
        }, "intro")
        .fromTo(firstSetEl.current, {
          transform: "matrix(0.88, -0.88, -0.33, 0.79, 0, 0)",
          y: -1400,
        }, {
          transform: "matrix(1, 0, 0, 1, 0, 0)",
          y: 0,
        }, "intro")
        .to(mobileCopyRef,{
          opacity: 1,
          duration: 0.2
        })

        setIsPageLoading(false);
        return () => { // optional

        };
      });
      
    } else {
      const scrollTriggerInstance = ScrollTrigger.getById("hello");
      if(scrollTriggerInstance) {
        scrollTriggerInstance.refresh();
        window.scrollTo(0,2000);
      }
      
    }

    return () => {
    }
  }, [ContainerRef, jelliesRef, brandRef, navRef, albums]);

  React.useEffect(() => {
    
    return () => {
      const scrollTriggerInstance = ScrollTrigger.getById("hello");
      if(tl.current && scrollTriggerInstance) {
        scrollTriggerInstance.kill();
        tl.current.kill();
      }
    }
  },[])


  const containerEl = useCallback(node => {
    if (node !== null) {
        setContainerRef(node);
    }
    }, []);

  const logoEl = useCallback(node => {
    if (node !== null) {
        setLogoRef(node);
    }
    }, []);
  

  

  
  


  return (
    <HomeLayout omitCopy setFilters={setFilters} filters={filters} navposition="fixed" isLoading={isPageLoading} gsapHide>
    <Seo title="Home" />
    <BrandScaling ref={logoEl}/>
    <HomeSection ref={containerEl}>
      <HomeSectionInner>
      <MobileCopy ref={mobileCopyReference} />
        <FirstGrid ref={firstSetEl}>
          {firstSet && firstSet.map((object) => {
            const slug = object._meta.uid;
            const data = object;
            const {
              artist,
              project,
              carousel,
              video_thumb,
            } = data;

            const firstImage = carousel.length > 0 ? carousel[0] : null;
            const image = firstImage && firstImage.image ? firstImage.image : video_thumb ? video_thumb : null;
            return (
              <Link to={`/${slug}`} key={slug}>
                <Thumbnail 
                  image={image && image.url ? `${image.url}&w=1000&q=70` : null } 
                  placeholder={image && image.url ? {
                    src:`${image.url}&w=100&blur=10&q=50`,
                    width: 100,
                    height: 100
                  } : null} 
                  alt={image && image.alt ? image.alt : null}
                  artist={artist} 
                  project={project}/>
              </Link>
            )

          })}
        </FirstGrid>
        <HomeGrid>
        {secondSet && secondSet.map((object) => {
            const slug = object._meta.uid;
            const data = object;
            const {
              artist,
              project,
              carousel,
              video_thumb,
            } = data;

            const firstImage = carousel.length > 0 ? carousel[0] : null;
            const image = firstImage && firstImage.image ? firstImage.image : video_thumb ? video_thumb : null;

            return (
              <Link to={`/${slug}`} key={slug}>
                <Thumbnail 
                  image={image && image.url ? `${image.url}&w=1000&q=70` : null }
                  placeholder={image && image.url ? {
                    src:`${image.url}&w=100&blur=10&q=50`,
                    width: 100,
                    height: 100
                  } : null} 
                  alt={image && image.alt ? image.alt : null}
                  artist={artist} 
                  project={project}/>
              </Link>
            )

          })}
        </HomeGrid>
        </HomeSectionInner>
    </HomeSection>
  </HomeLayout>
  )
}

export default IndexPage
