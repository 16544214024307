import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Logo } from 'components/Brand/Brand';

const Wrapper = styled.nav`
  padding: 16px 16px 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  box-sizing: border-box; 
 
`;

const SeenSoundsLogo = styled(Logo)`
  width: 100%;
  transform: translate3d(0px, 0px, 0px);
`

const LogoLink = styled(Link)` 
  width: 100%;
  display: flex;
  position: relative;
  transform-origin: left top;
`

const BrandScaling = React.forwardRef(({...props}, ref) => {

  return (
    <Wrapper {...props}>
      <LogoLink to="/" ref={ref}>
        <SeenSoundsLogo />
      </LogoLink>
    </Wrapper>
  )
})

export default BrandScaling;
